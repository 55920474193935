import React from 'react';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  & > path {
    fill: ${props => props.fillColor || '#8F9295'};
  }
  & > path:hover {
    fill: ${props => props.hoverColor || '#8F9295'};
  }
  margin-right: ${props => props.spacing || '16px'};
  cursor: pointer;
`;

export const IconComponent = ({ type, hoverColor, handleAction, defaultColor, spacing }) => {
  const renderIconView = () => {
    const content = {
      erase: () => {
        return (
          <SvgWrapper
            spacing={spacing}
            style={{ cursor: 'pointer' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <path
              d='M8.81802 8.81802C9.11091 8.52513 9.58579 8.52513 9.87868 8.81802L12 10.9393L14.1213 8.81802C14.4142 8.52512 14.8891 8.52512 15.182 8.81802C15.4749 9.11091 15.4749 9.58578 15.182 9.87868L13.0607 12L15.182 14.1213C15.4749 14.4142 15.4749 14.8891 15.182 15.182C14.8891 15.4749 14.4142 15.4749 14.1213 15.182L12 13.0607L9.87868 15.182C9.58579 15.4749 9.11091 15.4749 8.81802 15.182C8.52513 14.8891 8.52513 14.4142 8.81802 14.1213L10.9393 12L8.81802 9.87868C8.52513 9.58579 8.52513 9.11091 8.81802 8.81802Z'
              fill='#8F9295'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M5.63604 18.364C2.12132 14.8492 2.12132 9.15076 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.364C14.8492 21.8787 9.15076 21.8787 5.63604 18.364ZM6.6967 6.6967C3.76777 9.62563 3.76777 14.3744 6.6967 17.3033C9.62563 20.2322 14.3744 20.2322 17.3033 17.3033C20.2322 14.3744 20.2322 9.62563 17.3033 6.6967C14.3744 3.76777 9.62563 3.76777 6.6967 6.6967Z'
              fill='#8F9295'
            />
          </SvgWrapper>
        );
      },
      disabled: () => {
        return (
          <SvgWrapper
            spacing={spacing}
            style={{ cursor: 'not-allowed' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <path
              d='M12.7501 14.7993C13.1985 14.54 13.5001 14.0552 13.5001 13.5C13.5001 12.6716 12.8285 12 12.0001 12C11.1717 12 10.5001 12.6716 10.5001 13.5C10.5001 14.0552 10.8018 14.54 11.2501 14.7993V15.75C11.2501 16.1642 11.5859 16.5 12.0001 16.5C12.4143 16.5 12.7501 16.1642 12.7501 15.75V14.7993Z'
              fill='#8F9295'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M7.50012 9V6.75C7.50012 4.26472 9.51484 2.25 12.0001 2.25C14.4854 2.25 16.5001 4.26472 16.5001 6.75V9C17.7428 9 18.7501 10.0074 18.7501 11.25V17.25C18.7501 18.4926 17.7428 19.5 16.5001 19.5H7.50012C6.25748 19.5 5.25012 18.4926 5.25012 17.25V11.25C5.25012 10.0074 6.25748 9 7.50012 9ZM9.00012 6.75C9.00012 5.09315 10.3433 3.75 12.0001 3.75C13.657 3.75 15.0001 5.09315 15.0001 6.75V9H9.00012V6.75ZM16.5001 10.5C16.9143 10.5 17.2501 10.8358 17.2501 11.25V17.25C17.2501 17.6642 16.9143 18 16.5001 18H7.50012C7.08591 18 6.75012 17.6642 6.75012 17.25V11.25C6.75012 10.8358 7.08591 10.5 7.50012 10.5H16.5001Z'
              fill='#8F9295'
            />
          </SvgWrapper>
        );
      },
      cancel: () => {
        return (
          <SvgWrapper
            spacing={spacing}
            style={{ cursor: 'pointer' }}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <path
              d='M7.34835 7.34835C7.59243 7.10427 7.98816 7.10427 8.23223 7.34835L10 9.11612L11.7678 7.34835C12.0118 7.10427 12.4076 7.10427 12.6517 7.34835C12.8957 7.59242 12.8957 7.98815 12.6517 8.23223L10.8839 10L12.6516 11.7678C12.8957 12.0118 12.8957 12.4076 12.6516 12.6516C12.4076 12.8957 12.0118 12.8957 11.7678 12.6516L10 10.8839L8.23223 12.6516C7.98815 12.8957 7.59243 12.8957 7.34835 12.6516C7.10427 12.4076 7.10427 12.0118 7.34835 11.7678L9.11612 10L7.34835 8.23223C7.10427 7.98816 7.10427 7.59243 7.34835 7.34835Z'
              fill='#BA393A'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M4.6967 15.3033C1.76777 12.3744 1.76777 7.62563 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62563 18.2322 4.6967 15.3033ZM5.58058 5.58058C3.13981 8.02136 3.13981 11.9786 5.58058 14.4194C8.02136 16.8602 11.9786 16.8602 14.4194 14.4194C16.8602 11.9786 16.8602 8.02136 14.4194 5.58058C11.9786 3.13981 8.02136 3.13981 5.58058 5.58058Z'
              fill='#BA393A'
            />
          </SvgWrapper>
        );
      },
      send: () => {
        return (
          <SvgWrapper
            style={{ cursor: 'pointer' }}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M4.23698 5.86029C3.72469 4.57966 5.06682 3.34468 6.3005 3.96152L19.6944 10.6585C20.8 11.2112 20.8 12.789 19.6944 13.3417L6.3005 20.0387C5.06682 20.6555 3.72469 19.4205 4.23698 18.1399L6.69313 12.0001L4.23698 5.86029ZM8.00867 12.7501L5.62968 18.697L19.0236 12.0001L5.62968 5.30316L8.00867 11.2501H11.2509C11.6651 11.2501 12.0009 11.5859 12.0009 12.0001C12.0009 12.4143 11.6651 12.7501 11.2509 12.7501H8.00867Z'
              fill='#24272A'
            />
          </SvgWrapper>
        );
      },
      accepted: () => {
        return (
          <SvgWrapper
            spacing={spacing}
            style={{ cursor: 'pointer' }}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M16.0669 5.80806C16.311 6.05214 16.311 6.44786 16.0669 6.69194L7.94194 14.8169C7.69786 15.061 7.30214 15.061 7.05806 14.8169L3.93306 11.6919C3.68898 11.4479 3.68898 11.0521 3.93306 10.8081C4.17714 10.564 4.57286 10.564 4.81694 10.8081L7.5 13.4911L15.1831 5.80806C15.4271 5.56398 15.8229 5.56398 16.0669 5.80806Z'
              fill='#007D2B'
            />
          </SvgWrapper>
        );
      },
      pending: () => {
        return (
          <SvgWrapper
            spacing={spacing}
            style={{ cursor: 'pointer' }}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            fillColor={defaultColor}
            hoverColor={hoverColor}
          >
            <path
              d='M10.625 5.625C10.625 5.27982 10.3452 5 10 5C9.65482 5 9.375 5.27982 9.375 5.625V10C9.375 10.3452 9.65482 10.625 10 10.625H14.375C14.7202 10.625 15 10.3452 15 10C15 9.65482 14.7202 9.375 14.375 9.375H10.625V5.625Z'
              fill='#8F9295'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5ZM3.75 10C3.75 6.54822 6.54822 3.75 10 3.75C13.4518 3.75 16.25 6.54822 16.25 10C16.25 13.4518 13.4518 16.25 10 16.25C6.54822 16.25 3.75 13.4518 3.75 10Z'
              fill='#8F9295'
            />
          </SvgWrapper>
        );
      },
      show: () => {
        return (
          <SvgWrapper
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='visible-svg'
            style={{ cursor: 'pointer' }}
            onClick={handleAction}
            hoverColor={hoverColor}
            fillColor={defaultColor}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M15.7503 12C15.7503 14.0711 14.0713 15.75 12.0003 15.75C9.92919 15.75 8.25025 14.0711 8.25025 12L8.25026 11.9976C8.25157 9.92762 9.93 8.25 12.0003 8.25C14.0713 8.25 15.7503 9.92893 15.7503 12ZM12.0003 9.75C10.7576 9.75 9.75025 10.7574 9.75025 12C9.75025 13.2426 10.7576 14.25 12.0003 14.25C13.2429 14.25 14.2503 13.2426 14.2503 12C14.2503 10.7574 13.2429 9.75 12.0003 9.75Z'
              fill='#2F3337'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2.36143 11.6066C4.2876 8.25813 7.88071 6 12.0004 6C16.1286 6 19.728 8.2675 21.6513 11.6274C21.7925 11.8741 21.7781 12.1672 21.6391 12.3934C19.7129 15.7419 16.1198 18 12.0001 18C7.87192 18 4.27246 15.7325 2.34922 12.3726C2.20803 12.1259 2.22238 11.8328 2.36143 11.6066ZM3.87558 12.0002C5.59092 9.29036 8.59091 7.5 12.0004 7.5C15.4097 7.5 18.4095 9.29018 20.1249 11.9998C18.4096 14.7096 15.4096 16.5 12.0001 16.5C8.59083 16.5 5.59097 14.7098 3.87558 12.0002Z'
              fill='#2F3337'
            />
          </SvgWrapper>
        );
      },
      hide: () => {
        return (
          <SvgWrapper
            onClick={handleAction}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            style={{ cursor: 'pointer' }}
            hoverColor={hoverColor}
            fillColor={defaultColor}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14.5996 13.1414C13.7724 13.3751 12.9006 13.5 12.0001 13.5C11.0992 13.5 10.2269 13.375 9.39936 13.1411L9.39293 13.1393C8.36748 12.8486 7.41081 12.3907 6.55367 11.796C6.5439 11.7888 6.53397 11.7819 6.52389 11.7753C5.35222 10.9559 4.36821 9.88033 3.65103 8.62742C3.44526 8.26793 2.98703 8.14333 2.62754 8.3491C2.26806 8.55487 2.14345 9.0131 2.34922 9.37259C2.9861 10.4852 3.80679 11.4781 4.76962 12.3084L3.62331 12.9703C3.26459 13.1774 3.14169 13.6361 3.34879 13.9948C3.5559 14.3535 4.01459 14.4764 4.37331 14.2693L6.08199 13.2828C6.70946 13.6809 7.37912 14.0177 8.08272 14.2847L7.38907 15.4861C7.18197 15.8448 7.30487 16.3035 7.66359 16.5106C8.02231 16.7177 8.481 16.5948 8.68811 16.2361L9.55831 14.7289C10.1079 14.853 10.6728 14.9361 11.2499 14.9749V16.5C11.2499 16.9142 11.5857 17.25 11.9999 17.25C12.4141 17.25 12.7499 16.9142 12.7499 16.5V14.9749C13.3269 14.9362 13.8919 14.8531 14.4415 14.729L15.3117 16.2363C15.5188 16.595 15.9775 16.7179 16.3363 16.5108C16.695 16.3037 16.8179 15.845 16.6108 15.4863L15.9171 14.2848C16.6208 14.0179 17.2905 13.6811 17.918 13.2829L19.6268 14.2695C19.9856 14.4766 20.4443 14.3537 20.6514 13.995C20.8585 13.6363 20.7356 13.1776 20.3768 12.9705L19.2304 12.3086C20.1933 11.4782 21.0141 10.4853 21.651 9.37259C21.8568 9.01311 21.7322 8.55487 21.3727 8.3491C21.0132 8.14333 20.555 8.26794 20.3492 8.62742C19.6321 9.88025 18.6482 10.9557 17.4766 11.7751C17.4662 11.782 17.456 11.7891 17.4459 11.7965C16.589 12.3908 15.6327 12.8486 14.6076 13.1392L14.5996 13.1414Z'
              fill='#2F3337'
            />
          </SvgWrapper>
        );
      },
      remove: () => {
        return (
          <SvgWrapper
            onClick={handleAction}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            style={{ cursor: 'pointer' }}
            hoverColor={hoverColor}
            fillColor={defaultColor}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M6.87524 5V6.25H3.75024C3.40507 6.25 3.12524 6.52982 3.12524 6.875C3.12524 7.22018 3.40507 7.5 3.75024 7.5H4.45311L5.48616 15.2479C5.61036 16.1794 6.40496 16.8751 7.34471 16.8751H12.6559C13.5957 16.8751 14.3903 16.1794 14.5145 15.2479L15.5475 7.5H16.2502C16.5954 7.5 16.8752 7.22018 16.8752 6.875C16.8752 6.52982 16.5954 6.25 16.2502 6.25H13.1252V5C13.1252 3.96447 12.2858 3.125 11.2502 3.125H8.75024C7.71471 3.125 6.87524 3.96447 6.87524 5ZM8.75024 4.375C8.40507 4.375 8.12524 4.65482 8.12524 5V6.25H11.8752V5C11.8752 4.65482 11.5954 4.375 11.2502 4.375H8.75024ZM14.2865 7.5H5.71417L6.72519 15.0827C6.7666 15.3932 7.03146 15.6251 7.34471 15.6251H12.6559C12.9692 15.6251 13.234 15.3932 13.2754 15.0827L14.2865 7.5Z'
              fill='#8F9295'
            />
          </SvgWrapper>
        );
      },
      close: () => {
        return (
          <SvgWrapper
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleAction}
            spacing={spacing}
            hoverColor={hoverColor}
            fillColor={defaultColor}
          >
            <path
              d='M8.92879 7.51555C8.53826 7.12503 7.9051 7.12503 7.51457 7.51555C7.12405 7.90607 7.12405 8.53924 7.51457 8.92976L14.5858 16.001L7.51486 23.0719C7.12434 23.4624 7.12434 24.0956 7.51486 24.4861C7.90539 24.8766 8.53855 24.8766 8.92908 24.4861L16 17.4152L23.0709 24.4861C23.4614 24.8766 24.0946 24.8766 24.4851 24.4861C24.8757 24.0956 24.8757 23.4624 24.4851 23.0719L17.4142 16.001L24.4854 8.92976C24.876 8.53924 24.876 7.90607 24.4854 7.51555C24.0949 7.12503 23.4617 7.12503 23.0712 7.51555L16 14.5868L8.92879 7.51555Z'
              fill='#2F3337'
            />
          </SvgWrapper>
        );
      }
    };

    return content[type]();
  };

  return renderIconView(type);
};
